import React, { useEffect, useState, Suspense } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import dataArtikel from "./DataArtikel.js";
import { Container, Button } from "reactstrap";
import { Card, CardBody, CardTitle, CardSubtitle, CardText } from "reactstrap";
const DetailArtikel = () => {
  let { artikelId } = useParams();

  const Navbar = React.lazy(() => import("../../component/Navbar/NavBar"));
  const Footer = React.lazy(() => import("../../component/Footer/Footer"));

  const artikel = dataArtikel.filter((x) => x.id == artikelId);

  const [navItems, setNavItems] = useState([
    { id: 1, idnm: "/", navheading: "Beranda", active: false, box: false },
    {
      id: 2,
      idnm: "fitur",
      navheading: "Fitur",
      active: false,
      box: false,
    },
    {
      id: 3,
      idnm: "artikel",
      navheading: "Artikel",
      active: true,
      box: false,
    },
    {
      id: 4,
      idnm: "kontak",
      navheading: "Kontak",
      active: false,
      box: false,
    },
    {
      id: 5,
      idnm: "register",
      navheading: "Register",
      active: false,
      box: true,
    },
    // { id: 1, idnm: "home", navheading: "Home" },
    // { id: 2, idnm: "about", navheading: "About Us" },
    // { id: 3, idnm: "services", navheading: "Services" },
    // { id: 4, idnm: "features", navheading: "Features" },
    // { id: 5, idnm: "project", navheading: "Project" },
    // { id: 6, idnm: "clients", navheading: "Clients" },
    // { id: 7, idnm: "contact", navheading: "Contact Us" },
  ]);

  const [pos, setPos] = useState(document.documentElement.scrollTop);
  const [imglight, setImglight] = useState(false);
  const [navClass, setNavClass] = useState("");

  const scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    var scrollDown = document.documentElement.scrollDown;
    if (scrollup > pos) {
      setNavClass("nav-sticky");
      setImglight(false);
    }
  };

  //set preloader div
  const PreLoader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  }, []);

  return (
    <React.Fragment>
      <Suspense fallback={PreLoader()}>
        {/* Importing Navbar */}
        <Navbar navItems={navItems} navClass={navClass} imglight={imglight} />
        <Container>
          {/* header */}
          <div className="mt-5 mb-4">
            <h2 className="mb-4">{artikel[0].title}</h2>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-person-fill"
                viewBox="0 0 16 16"
              >
                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
              </svg>
              <span
                style={{
                  padding: "8px",
                }}
              >
                {artikel[0].creator}
              </span>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-calendar-fill"
                viewBox="0 0 16 16"
              >
                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16V4H0V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5z" />
              </svg>
              <span
                style={{
                  padding: "8px",
                }}
              >
                {artikel[0].tanggal}
              </span>
            </div>
          </div>
          {/* gambar */}
          <div className="p-4">
            <img
              src={artikel[0].gambar}
              alt={artikel[0].title + " | Artikel Desapedia"}
              width={"100%"}
              style={{}}
            />
          </div>
          {/* content */}
          <div>
            {artikel[0].content.map((x) => (
              <div className="my-3">
                {x.includes("photos") ? <img src={x}></img> : x}
              </div>
            ))}
          </div>
          {/* sources */}
          <div>
            <div>Penulis: {artikel[0].creator}</div>
            <h4>
              Bagikan:{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="32"
                height="32"
                viewBox="0 0 48 48"
              >
                <path
                  fill="#039be5"
                  d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"
                ></path>
                <path
                  fill="#fff"
                  d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="32"
                height="32"
                viewBox="0 0 48 48"
              >
                <radialGradient
                  id="yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1"
                  cx="19.38"
                  cy="42.035"
                  r="44.899"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#fd5"></stop>
                  <stop offset=".328" stop-color="#ff543f"></stop>
                  <stop offset=".348" stop-color="#fc5245"></stop>
                  <stop offset=".504" stop-color="#e64771"></stop>
                  <stop offset=".643" stop-color="#d53e91"></stop>
                  <stop offset=".761" stop-color="#cc39a4"></stop>
                  <stop offset=".841" stop-color="#c837ab"></stop>
                </radialGradient>
                <path
                  fill="url(#yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1)"
                  d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"
                ></path>
                <radialGradient
                  id="yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2"
                  cx="11.786"
                  cy="5.54"
                  r="29.813"
                  gradientTransform="matrix(1 0 0 .6663 0 1.849)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#4168c9"></stop>
                  <stop
                    offset=".999"
                    stop-color="#4168c9"
                    stop-opacity="0"
                  ></stop>
                </radialGradient>
                <path
                  fill="url(#yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2)"
                  d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"
                ></path>
                <path
                  fill="#fff"
                  d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"
                ></path>
                <circle cx="31.5" cy="16.5" r="1.5" fill="#fff"></circle>
                <path
                  fill="#fff"
                  d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="32"
                height="32"
                viewBox="0 0 50 50"
              >
                <path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"></path>
              </svg>
            </h4>
            <div>
              <div>Tag</div>
              <button
                style={{
                  borderRadius: "30px",
                  backgroundColor: "#FC663E",
                  color: "#fff",
                  padding: "6px 18px",
                  border: "none",
                  marginLeft: "6px",
                }}
              >
                #LoremIpsum
              </button>
              <button
                style={{
                  borderRadius: "30px",
                  backgroundColor: "#FC663E",
                  color: "#fff",
                  padding: "6px 18px",
                  border: "none",
                  marginLeft: "6px",
                }}
              >
                #Lorem
              </button>
              <button
                style={{
                  borderRadius: "30px",
                  backgroundColor: "#FC663E",
                  color: "#fff",
                  padding: "6px 18px",
                  border: "none",
                  marginLeft: "6px",
                }}
              >
                #LoremIpsumDolorSit
              </button>
              <button
                style={{
                  borderRadius: "30px",
                  backgroundColor: "#FC663E",
                  color: "#fff",
                  padding: "6px 18px",
                  border: "none",
                  marginLeft: "6px",
                }}
              >
                #Loremipsum
              </button>
            </div>
          </div>
          <div
            id="article"
            style={{
              background: "#fff",
            }}
          >
            <h4>ARTIKEL LAINNYA</h4>
            <div className="article-contains">
              <a
                className="color-border"
                href={
                  "/artikel/" + dataArtikel[0].title + "/" + dataArtikel[0].id
                }
                style={{
                  color: "#202020",
                }}
              >
                <Card
                  style={{
                    padding: "18px",
                    borderRadius: "24px",
                    marginBottom: "0",
                  }}
                >
                  <img
                    alt={dataArtikel[0].title + " Artikel Desapedia"}
                    src={dataArtikel[0].gambar}
                    className="rounded"
                  />
                  <CardBody>
                    <CardTitle tag="h5">{dataArtikel[0].title}</CardTitle>
                    <CardText>
                      {/* icon admin */}
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-person-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                        </svg>
                        <span
                          style={{
                            padding: "8px",
                          }}
                        >
                          {dataArtikel[0].creator}
                        </span>
                      </div>

                      {/* icon kalender */}
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-calendar-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16V4H0V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5z" />
                        </svg>
                        <span
                          style={{
                            padding: "8px",
                          }}
                        >
                          {dataArtikel[0].tanggal}
                        </span>
                      </div>
                    </CardText>
                  </CardBody>
                </Card>
              </a>
              <a
                className="color-border"
                href={
                  "/artikel/" + dataArtikel[1].title + "/" + dataArtikel[1].id
                }
                style={{
                  color: "#202020",
                }}
              >
                <Card
                  style={{
                    padding: "18px",
                    borderRadius: "24px",
                    marginBottom: "0",
                  }}
                >
                  <img
                    alt={dataArtikel[1].title + " Artikel Desapedia"}
                    src={dataArtikel[1].gambar}
                    className="rounded"
                  />
                  <CardBody>
                    <CardTitle tag="h5">{dataArtikel[1].title}</CardTitle>
                    <CardText>
                      {/* icon admin */}
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-person-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                        </svg>
                        <span
                          style={{
                            padding: "8px",
                          }}
                        >
                          {dataArtikel[1].creator}
                        </span>
                      </div>

                      {/* icon kalender */}
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-calendar-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16V4H0V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5z" />
                        </svg>
                        <span
                          style={{
                            padding: "8px",
                          }}
                        >
                          {dataArtikel[1].tanggal}
                        </span>
                      </div>
                    </CardText>
                  </CardBody>
                </Card>
              </a>
              <a
                className="color-border"
                href={
                  "/artikel/" + dataArtikel[2].title + "/" + dataArtikel[2].id
                }
                style={{
                  color: "#202020",
                }}
              >
                <Card
                  style={{
                    padding: "18px",
                    borderRadius: "24px",
                    marginBottom: "0",
                  }}
                >
                  <img
                    alt={dataArtikel[2].title + " Artikel Desapedia"}
                    src={dataArtikel[2].gambar}
                    className="rounded"
                  />
                  <CardBody>
                    <CardTitle tag="h5">{dataArtikel[2].title}</CardTitle>
                    <CardText>
                      {/* icon admin */}
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-person-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                        </svg>
                        <span
                          style={{
                            padding: "8px",
                          }}
                        >
                          {dataArtikel[2].creator}
                        </span>
                      </div>

                      {/* icon kalender */}
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-calendar-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16V4H0V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5z" />
                        </svg>
                        <span
                          style={{
                            padding: "8px",
                          }}
                        >
                          {dataArtikel[2].tanggal}
                        </span>
                      </div>
                    </CardText>
                  </CardBody>
                </Card>
              </a>
            </div>
          </div>
        </Container>
        <Footer />
      </Suspense>
    </React.Fragment>
  );
};

export default DetailArtikel;
