import React from 'react';
import './style.css';
const GaspolPolicy = () => {
  const textLinkStyle = {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
  };

  return (
    <div style={{ margin: 10 }}>
      <h1>KEBIJAKAN PRIVASI UNTUK APLIKASI GASPOL DRIVER</h1>
      <h2>/privacy mobile</h2>

      <h2 style={{ fontSize: 24, fontWeight: 'bold' }}>Pengantar</h2>

      <p>
        Pemberitahuan Privasi berikut ini menjelaskan bagaimana Kami PT Agriva
        Teknologi Nusantara memperoleh, mengumpulkan, menyimpan, menguasai,
        menggunakan, mengolah, menganalisis, memperbaiki, melakukan pembaruan,
        menampilkan, mengumumkan, mentransfer, mengungkapkan, dan melindungi
        Data Pribadi Anda ( "<strong>Memproses Data Pribadi</strong>" atau "
        <strong>Pemrosesan Data Pribadi</strong>" ).
      </p>

      <p>
        Harap baca Pemberitahuan Privasi ini secara menyeluruh untuk memastikan
        bahwa Anda memahami praktik pelindungan data Kami. Kami ingin membuat
        ini mudah dimengerti, jadi Kami sudah merangkum hal-hal penting di
        bagian Ringkasan. Istilah-istilah dalam huruf kapital yang digunakan
        dalam Pemberitahuan Privasi ini akan memiliki arti yang sama dengan
        istilah tersebut dalam Ketentuan Penggunaan yang berlaku antara Anda dan
        Gaspol.
      </p>

      <h2 style={{ fontSize: 24, fontWeight: 'bold', fontStyle: 'italic' }}>
        Pengakuan dan Persetujuan
      </h2>

      <p>
        Dengan menyetujui pemberitahuan Privasi, Anda mengakui bahwa Anda Telah
        membaca dan memahami Pemberitahuan Privasi ini serta menyetujui segala
        ketentuannya. Secara khusus, Anda sepakat dan memberikan persetujuan
        kepada Kami untuk Memproses Data Pribadi mereka oleh Kami. Kami dapat
        meminta bukti dari persetujuan tersebut kepada anda setiap saat.
      </p>

      <p>
        Anda dapat menarik persetujuan Anda untuk setiap atau segala Pemrosesan
        Data Pribadi Anda kapan saja dengan memberikan kepada Kami pemberitahuan
        yang wajar secara tertulis menggunakan rinician kontak yang disebutkan
        dibawah ini. Anda juga dapat menarik persetujuan untuk Kami mengirimkan
        komunikasi tertentu melalui fasilitas ”<i>opt-out</i>”, pilihan
        "berhenti berlangganan" yang tersedia dalam pesan yang Kami kirimkan,
        atau menggunakan opsi yang tersedia pada tiap-tiap media komunikasi yang
        Kami gunakan untuk menghubungi Anda. Anda harus memahami dan mengakui
        bahwa setelah penarikan persetujuan tersebut, Anda mungkin tidak lagi
        dapat menggunakan Aplikasi atau layanan. Penarikan persetujuan Anda
        dapat mengakibatkan penghentian layanan, penghapusan akun atau
        pengakhiran hubungan kontraktual Anda dengan Kami, dengan semua hak dan
        kewajiban yang muncul tetap dipenuhi sepenuhnya. Setelah menerima
        penarikan persetujuan dari Anda, Kami akan menginformasikan Anda tentang
        konsekuensi yang mungkin terjadi dari penarikan tersebut sehingga Anda
        dapat memutuskan apakah Anda tetap ingin menarik persetujuan.
      </p>

      <h2 style={{ fontSize: 24, fontWeight: 'bold' }}>Ringkasan</h2>

      <h2 style={{ fontSize: 20, fontWeight: 'bold' }}>
        Data apa yang kami kumpulkan tentang Anda?
      </h2>

      <p>
        Kami mengumpulkan Data Pribadi saat Anda menggunakan Aplikasi Kami,
        termasuk data identitas, data kontak, data kelayakan, data transaksi,
        data keuangan, data pembayaran, data catatan, (<i>log</i>) dan/atau data
        lokasi, kami juga mengumpulkan data perangkat dan teknis Anda, dan data
        lain yang mungkin Anda sampaikan atau kirimkan saat Anda menggunakan
        Aplikasi Kami. Jika Anda memilih untuk tidak menyediakan data tersebut,
        Kami mungkin tidak dapat menyediakan layanan dan Aplikasi kepada Anda
        secara normal.
      </p>

      <h2 style={{ fontSize: 20, fontWeight: 'bold' }}>
        Bagaimana Kami Menggunakan data tentang Anda?
      </h2>

      <p>
        Kami menggunakan data Anda untuk mengatur dan mengelola akun Anda dengan
        Kami, berkomunikasi dengan Anda, serta untuk menyediakan berbagai fitur,
        layanan dan fungsi bagi Anda pada Aplikasi Kami.
      </p>

      <p>
        Jika Anda adalah Penyedia Layanan, Kami menggunakan data Anda untuk
        memverifikasi bahwa anda dapat menyediakan layanan Anda kepada Pengguna,
        mengatur dan mengelola akun Anda dengan Kami, berkomunikasi dengan Anda,
        dan sebaliknya memberi Anda berbagai layanan dan fungsi yang tersedia di
        Aplikasi Kami.
      </p>

      <p>
        Kami juga menggunakan data Anda melakukan pemeliharaan atas Aplikasi
        Kami, dan untuk menyesuaikan produk dan layanan Kami dengan preferensi
        Anda. Selain itu, Kami menggunakan data Anda untuk memasarkan produk dan
        layanan Kami kepada Anda, dan dari perusahaan dalam afiliasi Kami,
        mitra, dan agen (dengan persetujuan Anda diwajibkan oleh peraturan
        Perundang-undangan Yang berlaku)
      </p>

      <br />
      <h2 style={{ fontSize: 24, fontWeight: 'bold' }}>
        Data Pribadi Yang Kami Kumpulkan
      </h2>
      <h2 style={{ fontSize: 20, fontWeight: 'bold' }}>
        1. Jenis-jenis Data Pribadi Yang Kami Kumpulkan Tentang Anda
      </h2>
      <p>
        Data Pribadi berarti data yang mengidentifikasi atau dapat digunakan
        untuk mengidentifikasi, menghubungi, atau menemukan orang atau perangkat
        yang terkait dengan data tersebut. Data Pribadi termasuk, namun tidak
        terbatas pada, nama, alamat, tanggal lahir, pekerjaan, nomor telepon,
        alamat email, rekening bank, dan rincian kartu kredit, jenis kelamin,
        identifikasi (termasuk paspor atau dokumen identitas nasional) atau
        tanda pengenal lainnya yang dikeluarkan pemerintah, data kendaraan,
        foto, kewarganegaraan, nomor telepon, Pengguna dan non-Pengguna di
        daftar kontak telepon seluler Anda, data kesehatan, data terkait
        keuangan data biometrik (termasuk namun tidak terbatas pada pengenalan
        sidik jari dan pengenalan wajah), dan data lainnya yang termasuk sebagai
        Data Pribadi sesuai dengan Peraturan Perundang-undangan yang Berlaku.
        Selain itu, data-data lain, termasuk profil pribadi, dan/atau Pengenal
        unik, dikaitkan atau digabungkan dengan Data Pribadi, maka data tersebut
        juga merupakan Data Pribadi.
      </p>

      <p>
        Jenis Data Pribadi yang Kami kumpulkan bergantung pada keadaan
        pengumpulkan dan sifat layanan yang diminta atau transaksi yang
        dilakukan.
      </p>

      <p>
        Sepanjang diizinkan oleh Peraturan Perundang-undangan yang Berlaku, Kami
        dapat memproses, mengumpulkan, menggunakan, menyimpan dan mentransfer
        jenis-jenis berbeda dari dari Data Pribadi, yang terdiri dari Data
        Pribadi umum dan spesifik/sensitif, tentang Anda yang telah kami
        kategorikan sebagai berikut:
      </p>

      <ol style={{ listStyleType: 'none' }}>
        <li>
          <p>
            <strong>a. Data Identitas</strong> termasuk nama, nama pengguna,
            kartu tanda penduduk, nomor pokok wajib pajak, identitas pengguna
            atau pengenal lainnya, jabatan, tanggal kelahiran, jenis kelamin,
            tempat kelahiran, pekerjaan, kebangsaaan, foto dan/atau data
            biometrik.
          </p>
        </li>
        <li>
          <p>
            <strong>b. Data Kontak</strong> termasuk alamat-alamat penagihan,
            alamat-alamat pengiriman dan labelnya, alamat-alamat email, nomor
            telepon dan daftar kontak.
          </p>
        </li>
        <li>
          <p>
            <strong>c. Data Kelayakan</strong> termasuk, dalam kaitannya dengan
            Penyedian Layanan, perizinan, persetujuan, dan otorisasi lain yang
            mengizinkan Anda, untuk mengoperasikan kendaraan dan menyediakan
            transportasi atau layanan lainnya, dan perincian polis asuransi.
          </p>
        </li>
        <li>
          <p>
            <strong>d. Data Transaksi</strong> mencakup pembelian atau pesanan
            yang anda lakukan, minat, preferensi, masukan, dan tanggapan survei
            Anda, sehubungan dengan Pengguna, jenis layanan yang dicari,
            perincian mengenai pengambilan dan/atau pengiriman, dan jenis
            layanan yang dapat diterima pada saat itu.
          </p>
        </li>
        <li>
          <p>
            <strong>e. Data Keuangan</strong> termasuk rincian rekening bank dan
            kartu pembayaran seperti jenis kartu atau rekening pembayaran yang
            digunakan, nama penerbit kartu atau rekening pembayaran tersebut,
            nomor identifikasi rekening tersebut atau kartu pembayaran, kode
            verifikasi kartu pembayaran atau akun tersebut, dan tanggal
            kadaluwarsa kartu pembayaran atau akun tersebut, sebagaimana
            berlaku, data virtual account, riwayat keuangan (termasuk namun
            tidak terbatas pada riwayat transaksi kartu pembayaran atau
            rekening, rincian kartu pembayaran atau rekening, kode CVV dan
            pemetaan dan/atau status dan keadaan dari kartu pembayaran atau
            rekening), dan data penilaian kredit (<i>credit scoring</i>).
          </p>
        </li>
        <li>
          <p>
            <strong>f. Data Teknis</strong> termasuk rincian tentang penggunaan
            Anda atas Aplikasi kami seperti identifikasi yang dihasilkan oleh
            Aplikasi (user id) alamat protokol internet (IP), data sebagai
            halaman web yang dilihat sebelumnya atau sesudahnya, durasi setiap
            kunjungan/sesi, identitas (ID) perangkat internet atau alamat
            kontrol akses media, ID periklanan dan informasi perangkat lainnya
            termasuk informasi mengenai produsen, model, dan sistem operasi
            perangkat yang Anda gunakan untuk mengakses Aplikasi dan{' '}
            <i>crash logs</i>.
          </p>
        </li>

        <li>
          <p>
            <strong>g. Data Pembayaran</strong> termasuk rincian pembayaran atau
            transfer yang dilakukan oleh Pengguna pada Aplikasi Kami, termasuk
            namun tidak terbatas pada data yang berkaitan dengan penggunaan,
            pembayaran, rincian penerima (termasuk rincian rekening mereka),
            metode pembayaran yang digunakan, jumlah pembayaran yang dibayarkan,
            rincian tagihan, rincian faktur.
          </p>
        </li>

        <li>
          <p>
            <strong>h. Data Perangkat</strong> termasuk data perangkat,
            diantaranya jenis perangkat yang anda digunakan untuk mengakses
            Aplikasi, termasuk model perangkat keras, sistem operasi dan
            versinya, perangkat lunak, nomor IMEI, nama file dan versinya,
            pilihan bahasa, pengenal perangkat unik, pengenal iklan, nomor seri,
            informasi gerakan perangkat, dan/atau informasi jaringan seluler.
          </p>
        </li>

        <li>
          <p>
            <strong>h. Data Catatan</strong> (<i>log</i>) termasuk data
            perangkat, diantaranya jenis perangkat yang anda digunakan untuk
            mengakses Aplikasi, termasuk model perangkat keras, sistem operasi
            dan versinya, perangkat lunak, nomor IMEI, nama file dan versinya,
            pilihan bahasa, pengenal perangkat unik, pengenal iklan, nomor seri,
            informasi gerakan perangkat, dan/atau informasi jaringan seluler.
          </p>
        </li>

        <li>
          <p>
            <strong>h. Data Lokasi </strong> termasuk data lokasi geografis
            waktu-nyata (real time) Anda
          </p>
        </li>
      </ol>

      <p>
        Kami dapat membuat, menggunakan, melisensikan, atau mengungkapkan Data
        Gabungan seperti data statistik atau demografi untuk tujuan apa pun.
        Data Gabungan dapat berasal dari Data Pribadi Anda tetapi tidak akan
        dianggap sebagai Data Pribadi karena data ini tidak akan secara langsung
        atau tidak langsung mengungkapkan identitas Anda karena Kami akan
        memastikan: (i) bahwa semua pengenal telah dihapus demikian rupa
        sehingga data, sendiri atau bersama-sama dengan data lain yang tersedia,
        tidak dapat dikaitkan atau dihubungkan dengan atau tidak dapat
        mengidentifikasi siapa pun, dan (ii) data tersebut kemudian digabungkan
        dengan data serupa sehingga data asli membentuk bagian dari kumpulan
        data yang lebih besar.
      </p>

      <br />

      <h2 style={{ fontSize: 20, fontWeight: 'bold' }}>
        2. Jika Anda menyediakan Data Pribadi yang tidak lengkap
      </h2>
      <p>
        Ketika Kami perlu mengumpulkan Data Pribadi berdasarkan hukum, atau
        berdasarkan ketentuan sebuah perjanjian yang Kami miliki dengan Anda,
        dan Anda memilih untuk tidak menyediakan Data Pribadi tersebut atau
        menyediakan Data Pribadi yang tidak lengkap kepada Kami saat diminta,
        Kami mungkin tidak dapat menyediakan layanan dan melaksanakan perjanjian
        yang Kami miliki atau sedang dalam proses untuk disepakati  dengan Anda.
      </p>

      <br />

      <h2 style={{ fontSize: 20, fontWeight: 'bold' }}>
        3. Bagaimana Kami Mengumpulkan Data Pribadi Anda
      </h2>
      <p>
        Data Pribadi yang kami kumpulkan dapat diberikan oleh Anda secara
        langsung atau oleh pihak ketiga (misalnya: ketika Anda mendaftar atau
        menggunakan Aplikasi, ketika Anda menghubungi layanan pelanggan Kami,
        atau sebaliknya ketika Anda menyediakan Data Pribadi kepada Kami). Kami
        dapat mengumpulkan data dalam berbagai macam bentuk dan tujuan (termasuk
        tujuan yang diizinkan berdasarkan Peraturan Perundang-undangan yang
        berlaku).
      </p>
      <ol style={{ listStyleType: 'none' }}>
        <li>
          <p>
            <strong>
              a. Data yang Kami kumpulkan dari Anda atau perangkat seluler Anda
              secara langsung
            </strong>
          </p>
          <p>
            Anda dapat memberikan kepada Kami Data Identitas, Kontak, Kelayakan,
            Profil, Keuangan dan Pembayaran, termasuk dalam keadaan di mana hal
            tersebut diminta oleh Kami atau dalam keadaan diharuskan oleh
            Peraturan Perundang-undangan yang Berlaku, saat berinteraksi dengan
            Kami secara langsung atau berkorespondesi dengan Kami melalui surat,
            email atau lainnya. Hal ini termasuk Data Pribadi yang Anda sediakan
            ketika Anda:
          </p>
          <ol style={{ listStyleType: 'none' }}>
            <li>i. Mendaftar dan membuat sebuah akun menggunakan Aplkasi;</li>
            <li>
              ii.Menggunakan Aplikasi, termasuk jika Anda mendaftar sebagai
              Penyedia Layanan atau menggunakan layanan sebagai Pengguna;
            </li>
            <li>
              iii.Melakukan pembayaran dengan menggunakan metode yang tersedia
              termasuk namun tidak terbatas pembayaran menggunakan kartu kredit,
              <i>virtual account</i>, transfer perbankan, fasilitas uang
              elektronik dan/atau dompet elektronik (baik sebagai pembayar atau
              penerima);
            </li>
            <li>
              iv. Mendaftar sebagai pemilik akun terdaftar atau terverifikasi
              dari sebuah fasilitas uang elektronik
            </li>
            <li>
              v. Menambahkan sumber pendanaan untuk pembayaran pada Aplikasi;
            </li>
            <li>vi. Menggunakan fitur chat pada Aplikasi; dan</li>
            <li>vii. vii.Memberikan masukan atau menghubungi Kami.</li>
          </ol>
        </li>

        <br />

        <li>
          <p>
            <strong>
              b. Data yang sikumpulkan setiap kali Anda menggunakan Aplikasi
            </strong>
          </p>
          <p>
            Anda dapat memberi Kami Data Teknis dan Lokasi setiap kali Anda
            menggunakan dan/atau mengunjungi Aplikasi. Setiap kali Anda
            mengakses Aplikasi melalui Perangkat Seluler Anda, Kami akan melacak
            dan mengumpulkan data lokasi geografis Anda secara waktu-nyata (
            <i>real-time</i>). Dalam beberapa situasi, Anda akan diminta untuk
            mengaktifkan <i>Global Positioning System</i> (<i>GPS</i>) pada
            perangkat seluler Anda agar kami dapat memberikan Anda pengalaman
            yang lebih baik dalam menggunakan Aplikasi (misalny, untuk memberi
            Anda data tentang seberapa dekat Penyedia Layanan dengan Anda). Anda
            selalu dapat memilih untuk menonaktifkan data pelacakan lokasi
            geografis pada Perangkat Seluler Anda untuk sementara. Namun, hal
            itu dapat mempengaruhi fungsi yang tersedia pada Aplikasi.
          </p>
          <p>
            Jika Anda menggunakan dan/atai ketika pembayaran atau transfer
            dilakukan melalui fasilitas uang elektronik dan/atau dompet
            elektronik yang tersedia pada Aplikasi Kami (baik sebagai pembayar
            atau penerima), Kami dapat mengumpulkan Data Keuangan, Profil, dan
            Pembayaran tertentu terkait dengan pembayaran dan transfer tersebut.
          </p>
          <p>
            Selain itu, setiap kali Anda menggunakan dan/atai mengunjungi
            Aplikasi, informasi tertentu. Perangkat Seluler dan/atau browser
            Anda juga memungkinkan Anda untuk menghapus cookies apa pun yang
            sebelumnya telah disimpan kapan pun. Namun, hal itu dapat
            memengaruhi fungsi yang tersedia pada Aplikasi.
          </p>
          <p>
            Kami juga mungkin menggunakan fitur-fitur yang disediakan oleh pihak
            ketiga dalam rangka meningkatkan layanan dan konten Kami, termasuk
            diantaranya ialah penilaian, penyesuaian, dan penyajian penawaran
            kepada setiap Pengguna berdasarkan penyesuaian tersebut, maka
            pengguna dapat mengatr melalui <i>browser</i>.
          </p>
        </li>

        <li>
          <p>
            <strong>c. Data yang dikumpukan dari pihak ketiga</strong>
          </p>
          <p>
            Kami juga dapat mengumpulkan Data Pribadi Anda dari pihak ketiga
            (termasuk agen, vendor, pemasok, kontraktor, mitra, institusi
            pemerintah dan pihak lainnya yang menyediakan layanan kepada Kami,
            mengumpulkan Data Pribadi dan/atau melakukan tugas atas nama Kami,
            atau dengan pihak yang melakukan kerja sama dengan Kami). Dalam
            kondisi tersebut, Kami hanya akan mengumpulkan Data Pribadi Anda
            untuk atau sehubungan dengan tujuan yang melibatkan pihak ketiga
            tersebut atau tujuan kerja sama Kami dengan pihak ketiga tersebut
            (tergantung pada kondisinya). Khususnya pada saat Anda mendaftarkan
            kartu pembayaran atau rekening melalui Aplikasi dan/atau mengakses,
            menambahkan dan/atau menghubungkan kartu pembayaran atau rekening
            Anda pada Aplikasi (sebagaimana berlaku di negara Anda), Kami dapat
            mengumpulkan data keuangan tertentu dan catatan finansial Anda
            (termasuk tetapi tidak terbatas pada catatan transaksi, rincian dan
            penempatan kartu pembayaran atau rekening, dan/atau status dan
            negara kartu pembayaran atau rekening Anda) dari penerbit kredensial
            pembayaran tersebut atau dari pihak ketiga lainnya
          </p>
        </li>

        <li>
          <p>
            <strong>
              d. Data tentang pihak ketiga yang Anda berikan kepada Kami
            </strong>
          </p>
          <p>
            Anda dapat memberikan kepada Kami Data Pribadi yang berkaitan dengan
            individu pihak ketiga lainnya (termasuk Data Pribadi yang berkaitan
            dengan pasangan Anda, anggota keluarga, teman, atau individu lain).
            Dalam kondisi tersebut, Anda tentu saja akan memerlukan persetujuan
            dari individu pihak ketiga lainnya tersebut — lihat “Pengakuan dan
            Persetujuan”, di atas, untuk informasi lebih lanjut.
          </p>
          <p>
            Jika Anda menggunakan fitur pesan di Aplikasi perangkat seluler
            Kami, Anda akan menyediakan nomor telepon yang tersimpan di dalam
            kontak telepon selular Anda untuk menjadikan Anda dapat menggunakan
            fitur pesan Kami serta tujuan lainnya.
          </p>
        </li>

        <li>
          <p>
            <strong>e. Rekaman dalam kendaraan</strong>
          </p>
          <p>
            Kami tidak mewajibkan atau mendukung, ataupun melarang, pemasangan
            atau penggunaan alat perekam dalam kendaraan di kendaraan apa pun.
            Jika perangkat tersebut dipasang dan digunakan di kendaraan, Kami
            tidak mengumpulkan Data Pribadi apapun dari rekaman dalam kendaraan
            oleh perangkat tersebut, dan pengumpulan Data Pribadi dari rekaman
            di dalam kendaraan tersebut tidak dilakukan atas nama Kami.
            Pengumpulan Data Pribadi dari rekaman di dalam kendaraan tersebut
            merupakan diskresi dari Penyedia Layanan. Kami tidak memiliki
            kendali atas pengumpulan Data Pribadi tersebut oleh Penyedia Layanan
            dan setiap penggunaan atau pengungkapan oleh Penyedia Layanan dari
            Data Pribadi yang dikumpulkan. Jika Anda adalah Penyedia Layanan dan
            memilih untuk mengunduh dan menggunakan perangkat rekaman di dalam
            kendaraan tersebut di kendaraan Anda, Anda bertanggung jawab
            sepenuhnya untuk memberitahukan Pengguna. Jika Anda adalah seorang
            Pengguna dan Anda keberatan atas penggunaan alat perekam dalam
            kendaraan Penyedia Layanan, Anda harus menyampaikan hal tersebut
            kepada Penyedia Layanan secara langsung dan hal tersebut menjadi
            tanggung jawab pribadi Anda.
          </p>
        </li>
      </ol>

      <br />

      <h2 style={{ fontSize: 24, fontWeight: 'bold' }}>
        Penggunaan Data Pribadi
      </h2>
      <h2 style={{ fontSize: 20, fontWeight: 'bold' }}>
        4. Bagaimana Kami Menggunakan Data Pribadi
      </h2>
      <p>
        Kami dapat menggunakan Data Pribadi yang dikumpulkan untuk tujuan
        sebagai berikut maupun untuk tujuan lain yang diizinkan oleh Peraturan
        Perundang-undangan yang Berlaku ("Tujuan"):
      </p>
      <br />

      <ol style={{ listStyleType: 'none' }}>
        <li>
          <p>
            <strong>
              a. Jika Anda adalah Pengguna, Kami dapat menggunakan Data Pribadi
              Anda:
            </strong>
          </p>
          <ol style={{ listStyleType: 'none' }}>
            <li>
              i. untuk mengidentifikasi dan mendaftarkan Anda sebagai Pengguna
              dan untuk membuat, memverifikasi, menonaktifkan, atau mengelola
              akun Anda pada Aplikasi Kami;
            </li>
            <li>
              ii. untuk memfasilitasi atau memungkinkan verifikasi apapun yang
              menurut pertimbangan Kami diperlukan sebelum Penyedia Layanan
              menyediakan layanan kepada Anda atau sebelum Kami mendaftarkan
              Anda sebagai Pengguna, termasuk melakukan proses Mengenal
              Pelanggan (Know Your Customer - KYC) dan penilaian kredit (credit
              scoring) (jika dibutuhkan); untuk memungkinkan Kami untuk
              menyediakan layanan, baik layanan yang telah tersedia, layanan
              yang Anda minta, maupun layanan yang akan tersedia mendatang
            </li>
            <li>
              iii. untuk mengolah dan memfasilitasi pesanan dan transaksi
              pembayaran yang Anda lakukan, termasuk sebagaimana berlaku,
              transaksi yang dilakukan melalui kartu pembayaran, uang elektronik
              dan/atau dompet elektronik, atau rekening yang tersedia pada
              Aplikasi;
            </li>
            <li>
              iv. untuk memberitahu Anda atas transaksi atau aktivitas yang
              terjadi dalam Aplikasi atau sistem lain yang terhubung dengan
              Aplikasi Kami;
            </li>
            <li>
              v. untuk memfasilitasi pentautan akun fello, jika anda
              mengaktifkan pentautan akun fello antara Gaspol Driver dan fello
              anda dengan aplikasi Kami.
            </li>
            <li>
              vi. untuk berkomunikasi dengan Anda dan mengirimkan Anda informasi
              sehubungan dengan penggunaan Aplikasi;
            </li>
            <li>
              vii. untuk memberitahu Anda mengenai segala pembaruan pada
              Aplikasi atau perubahan pada layanan yang disediakan;
            </li>
            <li>
              viii. untuk mengolah, memproses, menyelesaikan dan menanggapi
              keluhan, permasalahan, pertanyaan dan saran yang diterima dari
              Anda;
            </li>
            <li>
              ix. untuk melakukan pemeliharaan, mengembangkan, menguji,
              meningkatkan, dan mempersonalisasikan Aplikasi untuk memenuhi
              kebutuhan dan preferensi Anda sebagai Pengguna;
            </li>
            <li>
              x. untuk memantau dan menganalisis aktivitas, perilaku, dan data
              demografis pengguna termasuk kebiasaan dan penggunaan berbagai
              layanan yang tersedia di Aplikasi;
            </li>
            <li>
              xi. untuk mengirimkan Anda komunikasi pemasaran, iklan, voucher,
              survei, dan informasi secara langsung atau tertarget, dan
              informasi tentang penawaran atau promosi khusus dan saat acara,
              promosi, fitur, atau peluncuran tersebut terjadi.
            </li>
          </ol>
        </li>

        <br />

        <li>
          <p>
            <strong>
              b. Jika Anda adalah Penyedia Layanan, Kami dapat menggunakan Data
              Pribadi Anda;
            </strong>
          </p>

          <ol style={{ listStyleType: 'none' }}>
            <li>
              i.untuk mengidentifikasi Anda dan mendaftarkan Anda sebagai
              Penyedia Layanan dan membuat, mengelola atau memverifikasi akun
              Anda pada pada Aplikasi Kami;
            </li>
            <li>
              ii. untuk memfasilitasi atau memungkinkan verifikasi apapun yang
              menurut pertimbangan Kami mungkin perlu dilakukan sebelum Kami
              dapat mendaftarkan Anda sebagai Penyedia Layanan, termasuk untuk
              KYC (Know Your Customer) dan penilaian kredit (credit scoring)
              (jika dibutuhkan);
            </li>
            <li>
              iii. untuk memungkinkan Anda menyediakan layanan kepada Pengguna;
            </li>
            <li>
              iv. untuk mengolah, memfasilitasi, dan menyelesaikan pembayaran
              kepada Anda terkait dengan layanan yang telah Anda berikan;{' '}
            </li>
            <li>
              v. untuk berkomunikasi dengan Anda dan mengirimkan Anda informasi
              terkait dengan penyediaan layanan Anda, termasuk untuk
              menyampaikan pesanan Pengguna kepada Anda dan untuk memfasilitasi
              penerimaan Anda atas pesanan tersebut;
            </li>
            <li>
              vi. untuk menyediakan Anda pemberitahuan dan pembaruan pada
              Aplikasi atau perubahan cara penyediaan layanan;
            </li>
            <li>
              vii. untuk memberi Anda laporan terkait dengan layanan yang telah
              Anda sediakan;
            </li>
            <li>
              viii. untuk memproses dan menanggapi saran dari Pengguna terhadap
              layanan yang telah Anda berikan;
            </li>
            <li>
              ix. untuk memelihara, mengembangkan, menguji, meningkatkan, dan
              mempersonalisasikan Aplikasi untuk memenuhi kebutuhan dan
              preferensi Anda sebagai Penyedia Layanan;
            </li>
            <li>
              x. untuk memantau dan menganalisis aktivitas, perilaku, dan data
              demografis Pengguna termasuk kebiasaan dan daya tanggap Penyedia
              Layanan untuk berbagai layanan yang tersedia pada Aplikasi;
            </li>
            <li>
              xi. untuk menawarkan atau menyediakan layanan dari afiliasi atau
              mitra Kami;
            </li>
            <li>
              xii. untuk mengirimkan Anda komunikasi pemasaran, iklan, promo,
              survei, dan penawaran khusus atau promosi secara langsung atau
              ditargetkandan saat acara, promosi, fitur, atau peluncuran
              tersebut terjadi.
            </li>
          </ol>
        </li>

        <br />

        <li>
          <p>
            <strong>
              c. Baik Anda merupakan Pengguna ataupun Penyedia Layanan atau
              pihak yang menyediakan Data Pribadi kepada Kami, Kami juga dapat
              menggunakan Data Pribadi Anda secara lebih umum tujuan-tujuan
              sebagai berikut (walaupun dalam kasus tertentu Kami akan bertindak
              secara wajar tidak menggunakan Data Pribadi Anda lebih dari apa
              yang diperlukan untuk tujuan tersebut):
            </strong>
          </p>
          <ol style={{ listStyleType: 'none' }}>
            <li>i. untuk melakukan proses dan fungsi bisnis terkait;</li>
            <li>
              ii. untuk memantau penggunaan Aplikasi dan mengelola, mendukung
              serta meningkatkan efisiensi kinerja, perkembangan, pengalaman
              pengguna dan fungsi-fungsi Aplikasi;
            </li>
            <li>
              iii. untuk menyediakan bantuan sehubungan dengan dan untuk
              menyelesaikan kesulitan teknis atau masalah operasional dengan
              Aplikasi atau layanan;
            </li>
            <li>
              iv. untuk menghasilkan informasi statistik dan data analitik untuk
              tujuan pengujian, penelitian, analisis, pengembangan produk,
              kemitraan komersial, dan kerja sama;{' '}
            </li>
            <li>
              v. untuk mencegah, mendeteksi dan menyelidiki segala transaksi
              yang mencurigakan atau kegiatan yang dilarang, ilegal, tidak sah,
              atau curang;
            </li>
            <li>
              vi. untuk memfasilitasi transaksi aset bisnis (yang dapat berupa
              penggabungan, akuisisi, atau penjualan aset) yang melibatkan Kami
              dan/atau afiliasi Kami;
            </li>
            <li>
              vii. untuk memungkinkan Kami mematuhi semua kewajiban berdasarkan
              Peraturan Perundang-undangan yang Berlaku, termasuk namun tidak
              terbatas pada menanggapi permintaan, penyelidikan, atau keputusan
              sesuai dengan peraturan, mematuhi persyaratan pengarsipan,
              pelaporan, dan perizinan yang disyaratkan oleh hukum atau
              Peraturan Perundang-Undangan yang Berlaku dan melakukan
              pemeriksaan audit, uji tuntas dan penyelidikan; dan
            </li>
            <li>
              viii. untuk tujuan lain yang Kami beritahukan kepada Anda, dan
              apabila disyaratkan oleh Peraturan Perundang-undangan yang
              Berlaku, yang Kami memperoleh persetujuannya dari Anda.
            </li>
          </ol>
        </li>
      </ol>

      <br />

      <h2 style={{ fontSize: 20, fontWeight: 'bold' }}>
        5. Pengungkapan Data Pribadi Anda
      </h2>
      <p>
        Kami dapat mengungkapkan memberikan akses atau membagikan Data Pribadi
        Anda dengan afiliasi dan pihak lain untuk tujuan sebagai berikut ini
        serta untuk tujuan lain yang diizinkan oleh Peraturan Perundang-undangan
        yang Berlaku:
      </p>
      <ol style={{ listStyleType: 'none' }}>
        <li>
          a. jika Anda adalah Pengguna, untuk memungkinkan Penyedia Layanan,
          untuk melakukan atau memberikan layanan atau memproses pesanan,
          termasuk untuk menghubungi Anda;
        </li>
        <li>
          b. jika Anda adalah Penyedia Layanan, untuk memungkinkan Pengguna
          untuk meminta atau menerima layanan dari Anda, termasuk untuk
          menghubungi Anda dan menyelenggarakan promosi, kontes, atau layanan
          khusus yang tersedia pada Aplikasi;
        </li>
        <li>
          c. menanggapi pertanyaan terkait regulasi, pertanyaan, permintaan,
          penyelidikan, keputusan, pelaporan, untuk mematuhi persyaratan dan
          ketentuan pengarsipan, sebagaimana yang ditentukan dalam Peraturan
          Perundang-undangan yang Berlaku;
        </li>
        <li>
          d. jika diinstruksikan, diminta, disyaratkan atau diperbolehkan oleh
          pemerintah yang berwenang, untuk tujuan sebagaimana disebutkan dalam
          surat, kebijakan pemerintah, peraturan pemerintah atau Peraturan
          Perundang-undangan yang Berlaku;
        </li>
        <li>
          e. jika terdapat proses hukum dalam bentuk apapun antara Anda dengan
          Kami, atau antara Anda dengan pihak lain, sehubungan dengan, atau
          terkait dengan layanan, untuk keperluan proses hukum tersebut;
        </li>
        <li>
          f. sehubungan dengan segala proses verifikasi Kami atau pihak ketiga
          perlukan sebelum Penyedia Layanan menyediakan layanan kepada Anda atau
          Kami mendaftarkan Anda sebagai pengguna, termasuk proses KYC (Know
          Your Customer) dan penilaian kredit (credit scoring);
        </li>
        <li>
          g. saat layanan Kami tersedia di platform pihak ketiga manapun, (i)
          untuk membantu Kami memperoleh Data Pribadi Anda dan/atau (ii)
          mendaftarkan Anda atau membantu Anda menggunakan layanan Kami pada
          platform pihak ketiga tersebut;
        </li>
        <li>
          h. dalam keadaan darurat terkait kesehatan atau keselamatan Anda (baik
          Anda adalah Pengguna atau Penyedia Layanan) untuk keperluan menangani
          keadaan darurat tersebut;
        </li>
        <li>
          i. dalam situasi terkait kesehatan Anda atau kepentingan umum (baik
          Anda adalah Penyedia Layanan atau Pengguna), Kami dapat membagikan
          Data Pribadi Anda kepada institusi pemerintah yang berwenang dan/atau
          institusi lainnya yang dapat ditunjuk oleh pemerintah yang berwenang
          atau memiliki kerja sama dengan Kami, untuk tujuan pelacakan kontak,
          mendukung inisiatif, kebijakan atau program pemerintah, kesehatan
          publik dan tujuan lainnya sebagaimana dibutuhkan secara wajar;
        </li>
        <li>
          j. sehubungan dengan, penggabungan, penjualan aset perusahaan,
          konsolidasi atau restrukturisasi, pembiayaan atau akuisisi semua atau
          sebagian dari bisnis Kami oleh atau ke perusahaan lain, untuk
          keperluan transaksi tersebut (bahkan jika kemudian transaksi tidak
          dilanjutkan);
        </li>
        <li>
          k. sehubungan dengan proses klaim asuransi, Kami akan membagikan Data
          Pribadi Anda untuk tujuan pemrosesan klaim asuransi kepada perusahaan
          asuransi yang bekerja sama atau berkolaborasi dengan Kami;
        </li>
        <li>
          l. kepada pihak ketiga (termasuk agen, vendor, pemasok, kontraktor,
          mitra, dan pihak lain yang menyediakan layanan kepada Kami atau Anda,
          melakukan tugas atas nama Kami, atau pihak dengan siapa Kami
          mengadakan kerja sama komersial), untuk atau sehubungan dengan tujuan
          di mana pihak ketiga tersebut terlibat, untuk melaksanakan
          pengungkapan kepada pihak ketiga terkait yang secara teknis diperlukan
          untuk memproses transaksi Anda atau tujuan kerja sama Kami dengan
          pihak ketiga tersebut (tergantung keadaannya), yang dapat mencakup
          diperbolehkannya pihak ketiga tersebut untuk memperkenalkan atau
          menawarkan produk atau layanan kepada Anda, melakukan autentikasi
          terhadap Anda atau menghubungkan Anda dengan akun atau melakukan
          kegiatan lain termasuk pemasaran, penelitian, analisis dan
          pengembangan produk; dan
        </li>
        <li>
          m. Dengan afiliasi atau pihak ketiga lainnya yang membantu Kami dalam
          menyediakan Aplikasi, untuk mengoperasikan bisnis Kami (termasuk,
          ketika Anda berlangganan milis (mailing list) Kami, untuk tujuan
          pemasaran langsung), atau untuk tujuan pengolahan data atas nama Kami.
        </li>
      </ol>
      <p>
        Ketika Data Pribadi tidak perlu dikaitkan dengan Anda, Kami akan
        melakukan upaya yang wajar untuk menghapus dikaitkannya Data Pribadi
        tersebut dengan Anda sebagai individu sebelum mengungkapkan atau berbagi
        data tersebut. Kami tidak akan menjual atau menyewakan Data Pribadi
        Anda.Selain sebagaimana diatur dalam Pemberitahuan Privasi ini, Kami
        dapat mengungkapkan dan membagikan Data Pribadi Anda jika Kami
        memberitahu Anda or Kami telah memperoleh persetujuan Anda untuk
        pengungkapan atau pembagian tersebut.
      </p>

      <br />

      <h2 style={{ fontSize: 20, fontWeight: 'bold' }}>
        6. Transfer Internasional
      </h2>
      <p>
        Saat Anda menggunakan Aplikasi dan layanan Kami di negara lain dimana
        Aplikasi Kami dapat diakses (“Negara Tujuan”), Kami dapat mentransfer
        Data Pribadi Anda dari negara asal (“Negara Asal”) ke Negara Tujuan
        untuk membuat Anda dapat mengakses dan memastikan pengalaman pelanggan
        yang lancar ketiga Anda menggunakan Aplikasi Kami di luar negeri. Data
        Pribadi Anda juga dapat disimpan atau diolah di luar negara Anda oleh
        personel Kami yang bekerja untuk Kami di negara lain, atau oleh penyedia
        layanan pihak ketiga, vendor, pemasok, mitra, kontraktor, atau afiliasi
        Kami. Kami akan mematuhi seluruh Peraturan Perundang-undangan yang
        Berlaku dan menggunakan seluruh usaha yang terbaik untuk memastikan
        afiliasi Kami di negara lain dan seluruh pihak ketiga penyedia layanan
        menyediakan tingkat pelindungan yang setara dengan komitmen Kami dalam
        Pemberitahuan Privasi ini dan sebagaimana ditentukan berdasarkan hukum
        di negara Anda. Anda memahami dan menyetujui transfer Data Pribadi Anda
        keluar dari negara asal Anda dan/atau Negara Asal sebagaimana dijelaskan
        dalam Pemberitahuan Privasi ini.
      </p>

      <br />

      <h2 style={{ fontSize: 20, fontWeight: 'bold' }}>7. Keamanan Data</h2>
      <p>
        Kerahasiaan Data Pribadi Anda adalah hal yang terpenting bagi Kami. Kami
        akan memberlakukan upaya terbaik untuk melindungi dan mengamankan Data
        Pribadi Anda dari akses, pengumpulan, penggunaan atau pengungkapan oleh
        orang-orang yang tidak berwenang dan dari pengolahan yang bertentangan
        dengan hukum, kehilangan yang tidak disengaja, pemusnahan dan kerusakan
        atau risiko serupa. Namun, dikarenakan pengiriman data melalui internet
        tidak sepenuhnya aman, Kami tidak dapat sepenuhnya menjamin bahwa Data
        Pribadi tersebut tidak akan dicegat, diakses, diungkapkan, diubah atau
        dihancurkan oleh pihak ketiga yang tidak berwenang, karena faktor-faktor
        di luar kendali Kami. Anda bertanggung jawab untuk menjaga kerahasiaan
        detail akun Anda dan Anda wajib untuk tidak membagikan detail akun Anda,
        termasuk kata sandi Anda dan One Time Password (OTP) dengan siapapun,
        dan Anda harus selalu menjaga dan bertanggung jawab atas keamanan
        perangkat yang Anda gunakan.
      </p>

      <br />

      <h2 style={{ fontSize: 20, fontWeight: 'bold' }}>8. Penyimpanan Data</h2>
      <p>
        Data Pribadi Anda hanya akan disimpan selama diperlukan untuk memenuhi
        tujuan dari pengumpulannya, selama masa retensi atau selama penyimpanan
        tersebut diperlukan atau diperbolehkan oleh Peraturan Perundang-undangan
        yang Berlaku. Kami akan berhenti menyimpan Data Pribadi, atau menghapus
        maksud dari dikaitkannya Data Pribadi tersebut dengan Anda sebagai
        individu, segera setelah dianggap bahwa tujuan pengumpulan Data Pribadi
        tersebut tidak lagi dibutuhkan dengan menyimpan Data Pribadi, terdapat
        permintaan dari Anda untuk melakukan penghapusan akun Anda, dan
        penyimpanan tidak lagi diperlukan untuk tujuan bisnis atau secara hukum.
      </p>
      <p>
        Kami akan menghapus dan/atau menganonimkan Data Pribadi Pengguna yang
        ada di bawah kendali Kami apabila (i) Data Pribadi Pengguna tidak lagi
        diperlukan untuk memenuhi tujuan dari pengumpulannya; (ii) berakhirnya
        masa retensi dan (iii) penyimpanan tidak lagi diperlukan untuk tujuan
        kepatuhan menurut ketentuan Peraturan Perundang-undangan yang Berlaku.
      </p>
      <p>
        Mohon diperhatikan bahwa masih ada kemungkinan bahwa beberapa Data
        Pribadi Anda disimpan atau dikuasai oleh pihak lain termasuk institusi
        pemerintah dengan cara tertentu. Dalam hal Kami membagikan Data Pribadi
        Anda kepada institusi pemerintah yang berwenang dan/atau institusi
        lainnya yang dapat ditunjuk oleh pemerintah yang berwenang atau memiliki
        kerja sama dengan Kami, Anda menyetujui dan mengakui bahwa penyimpanan
        Data Pribadi Anda oleh institusi terkait akan mengikuti kebijakan
        penyimpanan data masing-masing institusi tersebut.
      </p>
      <p>
        Data yang disampaikan melalui komunikasi antara Pembeli dan Penjual yang
        dilakukan selain melalui penggunaan Aplikasi (seperti melalui panggilan
        telepon, SMS, pesan seluler atau cara komunikasi lainnya dan pengumpulan
        atas Data Pribadi Anda oleh agen Kami) juga dapat disimpan dengan
        beberapa cara. Kami tidak mengizinkan Pemrosesan Data Pribadi antara
        Pembeli dan Penjual yang terjadi diluar Aplikasi Kami. Oleh karena itu,
        Pembeli dan Penjual bertanggung jawab penuh terhadap Pemrosesan Data
        Pribadi tersebut.
      </p>
      <p>
        Sepanjang diizinkan oleh Peraturan Perundang-undangan yang Berlaku, Anda
        membebaskan Kami dari dan terhadap setiap dan segala klaim, kerugian,
        kewajiban, biaya, kerusakan, dan ongkos (termasuk tetapi tidak terbatas
        pada biaya hukum dan pengeluaran biaya ganti rugi penuh) yang dihasilkan
        secara langsung atau tidak langsung dari setiap aktivitas yang dilakukan
        diluar Aplikasi kami.
      </p>

      <br />

      <h2 style={{ fontSize: 24, fontWeight: 'bold' }}>
        HAK ANDA SEBAGAI PENGGUNA
      </h2>
      <h2 style={{ fontSize: 20, fontWeight: 'bold' }}>9. HAK HUKUM ANDA</h2>

      <p>
        Anda mungkin memiliki hak tertentu berdasarkan Peraturan
        Perundang-undangan yang Berlaku untuk meminta kepada Kami terhadap akses
        kepada, koreksi dari dan/atau penghapusan terhadap Data Pribadi Anda
        yang berada dalam penguasaan dan kendali Kami. Anda dapat menggunakan
        hak-hak ini dengan menghubungi Kami pada rincian yang disediakan pada
        bagian di bawah ini.
      </p>

      <p>
        Kami dapat menolak permintaan Anda terhadap akses pada, koreksi dari
        dan/atau penghapusan terhadap, sebagian atau semua Data Pribadi Anda
        yang Kami kuasai atau kendalikan jika diwajibkan atau diperlukan
        berdasarkan Peraturan Perundang-undangan yang Berlaku. Hal ini termasuk
        dalam keadaan di mana Data Pribadi tersebut dapat berisi referensi
        kepada orang lain atau di mana permintaan untuk akses atau permintaan
        untuk mengoreksi atau menghapus adalah untuk alasan yang Kami anggap
        tidak relevan, tidak serius, atau mengada-ada atau terindikasi terkait
        tindakan pelanggaran ketentuan penggunaan atau dengan aktivitas atau
        pelanggaran hukum.
      </p>

      <p>
        Sesuai Peraturan Perundang-undangan yang Berlaku, Kami memiliki hak
        untuk membebankan biaya administratif untuk setiap permintaan akses
        dan/atau koreksi.
      </p>

      <br />

      <h2 style={{ fontSize: 20, fontWeight: 'bold' }}>
        10. Situs Web dan Layanan Lain
      </h2>
      <p>
        Aplikasi dan Materi Pemasaran (sebagaimana didefinisikan di bawah) dapat
        berisi tautan ke situs web yang dioperasikan oleh pihak ketiga. Segala
        Pemrosesan Data Pribadi pada situs web tersebut sepenuhnya dikendalikan
        oleh pihak ketiga dan berada dibawah pertanggungjawaban pihak ketiga.
        Jika Anda setuju untuk mengakses situs web yang dioperasikan oleh pihak
        ketiga tersebut, maka Anda tunduk pada syarat dan ketentuan serta
        pemberitahuan privasi dari situs web pihak ketiga. Oleh karena itu, Kami
        sarankan Anda untuk membaca syarat dan ketentuan dan pemberitahuan
        privasi dari situs web pihak ketiga tersebut untuk mengetahui bagaimana
        mereka mengumpulkan dan menggunakan Data Pribadi Anda.
      </p>
      <p>
        Saat Anda menggunakan Aplikasi Kami dan mengaktifkan fitur sidik jari
        (fingerprint) dan/atau pengenalan wajah (facial recognition) di
        Perangkat Seluler Anda untuk keperluan autentikasi, harap dicatat bahwa
        Kami tidak menyimpan data biometrik tersebut. Kecuali diberitahukan lain
        kepada Anda, data tersebut tersimpan di perangkat seluler Anda dan dapat
        disimpan oleh pihak ketiga, seperti pembuat Perangkat Seluler Anda. Anda
        setuju dan mengakui bahwa Kami tidak bertanggung jawab atas setiap akses
        tidak sah atau kerugian apapun atas data biometrik yang tersimpan di
        Perangkat Seluler Anda.
      </p>
      <p>
        Iklan yang terdapat pada Aplikasi atau Materi Pemasaran Kami berfungsi
        sebagai tautan ke situs web pengiklan dan dengan demikian segala data
        yang mereka kumpulkan berdasarkan klik Anda pada tautan itu akan
        dikumpulkan dan digunakan oleh pengiklan yang relevan sesuai dengan
        kebijakan privasi pengiklan tersebut.
      </p>

      <br />

      <h2 style={{ fontSize: 24, fontWeight: 'bold' }}>Deskripsi Kami</h2>
      <p>
        Gaspol Driver merupakan suatu aplikasi yang menawarkan layanan
        transportasi umum seperti transportasi motor atau mobil yang bisa
        dipesan secara online oleh masyarakat desa. Aplikasi ini didirikan di
        Indonesia pada tahun 2024.
      </p>
      <p>
        Berikut adalah beberapa layanan utama yang ditawarkan oleh aplikasi
        Gaspol Dirver:
      </p>

      <ol style={{ listStyleType: 'none' }}>
        <li>
          1. <strong>Transportasi:</strong> Layanan Transportasi Gaspol Driver
          memungkinkan pengguna untuk memesan kendaraan seperti motor, mobil,
          taksi secara online. Pengguna dapat memilih jenis kendaraan dan rute
          perjalanan mereka sesuai kebutuhan.
        </li>
        <li>
          2. <strong>Pengiriman Barang:</strong> Gaspol Driver juga menyediakan
          layanan pengiriman barang yang memungkinkan pengguna untuk mengirim
          atau menerima paket dengan cepat dan efisien. Ini bisa digunakan untuk
          mengirim barang ke alamat tertentu atau bahkan untuk mengantarkan
          makanan dari restoran favorit.
        </li>
        <li>
          3. <strong>Pemesanan Makanan:</strong> Pengguna dapat memesan makanan
          dari berbagai restoran dan warung melalui aplikasi Gaspol Driver.
          Layanan ini memungkinkan pengguna untuk menjelajahi menu, memesan
          makanan, dan mengatur pengiriman ke lokasi mereka.
        </li>
      </ol>
    </div>
  );
};

export default GaspolPolicy;
