import React, { Component, Suspense } from "react";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
} from "reactstrap";
import ArticleImage from "../assets/images/Article/Article-image.png";
import OuterLayer from "../assets/images/Article/Outer-layer.png";
import dataArtikel from "../pages/Artikel/DataArtikel.js";

// Importing Section
const Navbar = React.lazy(() => import("../component/Navbar/NavBar"));
const Footer = React.lazy(() => import("../component/Footer/Footer"));

class Article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataArtikel: dataArtikel,
      navItems: [
        { id: 1, idnm: "/", navheading: "Beranda", active: false, box: false },
        {
          id: 2,
          idnm: "fitur",
          navheading: "Fitur",
          active: false,
          box: false,
        },
        {
          id: 3,
          idnm: "artikel",
          navheading: "Artikel",
          active: true,
          box: false,
        },
        {
          id: 4,
          idnm: "kontak",
          navheading: "Kontak",
          active: false,
          box: false,
        },
        {
          id: 5,
          idnm: "register",
          navheading: "Register",
          active: false,
          box: true,
        },
        // { id: 1, idnm: "home", navheading: "Home" },
        // { id: 2, idnm: "about", navheading: "About Us" },
        // { id: 3, idnm: "services", navheading: "Services" },
        // { id: 4, idnm: "features", navheading: "Features" },
        // { id: 5, idnm: "project", navheading: "Project" },
        // { id: 6, idnm: "clients", navheading: "Clients" },
        // { id: 7, idnm: "contact", navheading: "Contact Us" },
      ],
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: false });
    }
  };

  //set preloader div
  PreLoader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={this.PreLoader()}>
          {/* Importing Navbar */}
          <Navbar
            navItems={this.state.navItems}
            navClass={this.state.navClass}
            imglight={this.state.imglight}
          />

          <Container id="article">
            <h2>ARTIKEL</h2>
            <div className="article-contains">
              {this.state.dataArtikel.map((data, i) => (
                <a
                  className="color-border"
                  href={"/artikel/" + data.title + "/" + data.id}
                  style={{
                    color: "#202020",
                  }}
                >
                  <Card
                    style={{
                      padding: "18px",
                      borderRadius: "24px",
                      marginBottom: "0",
                    }}
                  >
                    <img
                      alt={data.title + " Artikel Desapedia"}
                      src={data.gambar}
                      className="rounded"
                    />
                    <CardBody>
                      <CardTitle tag="h5">{data.title}</CardTitle>
                      <CardText>
                        {/* icon admin */}
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-person-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                          </svg>
                          <span
                            style={{
                              padding: "8px",
                            }}
                          >
                            {data.creator}
                          </span>
                        </div>

                        {/* icon kalender */}
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-calendar-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16V4H0V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5z" />
                          </svg>
                          <span
                            style={{
                              padding: "8px",
                            }}
                          >
                            {data.tanggal}
                          </span>
                        </div>
                      </CardText>
                    </CardBody>
                  </Card>
                </a>
              ))}
              {/* <div className="color-border">
                <div className="article-card">
                  <div className="article-image">
                    <img src={ArticleImage} alt="" />
                    <img src={OuterLayer} className="img-layer" alt="" />
                  </div>
                  <h3>
                    Batal Dipecat, Guru Honorer yang Kritik Ridwan Kamil Tidak
                    Mau Kembali Ngajar: Merasa Bersalah
                  </h3>
                  <p>16/03/2023, 11:38 WIB</p>
                  <p>Kompas.com</p>
                </div>
              </div>
              <div className="color-border">
                <div className="article-card">
                  <div className="article-image">
                    <img src={ArticleImage} alt="" />
                    <img src={OuterLayer} className="img-layer" alt="" />
                  </div>
                  <h3>
                    Batal Dipecat, Guru Honorer yang Kritik Ridwan Kamil Tidak
                    Mau Kembali Ngajar: Merasa Bersalah
                  </h3>
                  <p>16/03/2023, 11:38 WIB</p>
                  <p>Kompas.com</p>
                </div>
              </div>
              <div className="color-border">
                <div className="article-card">
                  <div className="article-image">
                    <img src={ArticleImage} alt="" />
                    <img src={OuterLayer} className="img-layer" alt="" />
                  </div>
                  <h3>
                    Batal Dipecat, Guru Honorer yang Kritik Ridwan Kamil Tidak
                    Mau Kembali Ngajar: Merasa Bersalah
                  </h3>
                  <p>16/03/2023, 11:38 WIB</p>
                  <p>Kompas.com</p>
                </div>
              </div>
              <div className="color-border">
                <div className="article-card">
                  <div className="article-image">
                    <img src={ArticleImage} alt="" />
                    <img src={OuterLayer} className="img-layer" alt="" />
                  </div>
                  <h3>
                    Batal Dipecat, Guru Honorer yang Kritik Ridwan Kamil Tidak
                    Mau Kembali Ngajar: Merasa Bersalah
                  </h3>
                  <p>16/03/2023, 11:38 WIB</p>
                  <p>Kompas.com</p>
                </div>
              </div>
              <div className="color-border">
                <div className="article-card">
                  <div className="article-image">
                    <img src={ArticleImage} alt="" />
                    <img src={OuterLayer} className="img-layer" alt="" />
                  </div>
                  <h3>
                    Batal Dipecat, Guru Honorer yang Kritik Ridwan Kamil Tidak
                    Mau Kembali Ngajar: Merasa Bersalah
                  </h3>
                  <p>16/03/2023, 11:38 WIB</p>
                  <p>Kompas.com</p>
                </div>
              </div>
              <div className="color-border">
                <div className="article-card">
                  <div className="article-image">
                    <img src={ArticleImage} alt="" />
                    <img src={OuterLayer} className="img-layer" alt="" />
                  </div>
                  <h3>
                    Batal Dipecat, Guru Honorer yang Kritik Ridwan Kamil Tidak
                    Mau Kembali Ngajar: Merasa Bersalah
                  </h3>
                  <p>16/03/2023, 11:38 WIB</p>
                  <p>Kompas.com</p>
                </div>
              </div>
              <div className="color-border">
                <div className="article-card">
                  <div className="article-image">
                    <img src={ArticleImage} alt="" />
                    <img src={OuterLayer} className="img-layer" alt="" />
                  </div>
                  <h3>
                    Batal Dipecat, Guru Honorer yang Kritik Ridwan Kamil Tidak
                    Mau Kembali Ngajar: Merasa Bersalah
                  </h3>
                  <p>16/03/2023, 11:38 WIB</p>
                  <p>Kompas.com</p>
                </div>
              </div>
              <div className="color-border">
                <div className="article-card">
                  <div className="article-image">
                    <img src={ArticleImage} alt="" />
                    <img src={OuterLayer} className="img-layer" alt="" />
                  </div>
                  <h3>
                    Batal Dipecat, Guru Honorer yang Kritik Ridwan Kamil Tidak
                    Mau Kembali Ngajar: Merasa Bersalah
                  </h3>
                  <p>16/03/2023, 11:38 WIB</p>
                  <p>Kompas.com</p>
                </div>
              </div>
              <div className="color-border">
                <div className="article-card">
                  <div className="article-image">
                    <img src={ArticleImage} alt="" />
                    <img src={OuterLayer} className="img-layer" alt="" />
                  </div>
                  <h3>
                    Batal Dipecat, Guru Honorer yang Kritik Ridwan Kamil Tidak
                    Mau Kembali Ngajar: Merasa Bersalah
                  </h3>
                  <p>16/03/2023, 11:38 WIB</p>
                  <p>Kompas.com</p>
                </div>
              </div>
              <div className="color-border">
                <div className="article-card">
                  <div className="article-image">
                    <img src={ArticleImage} alt="" />
                    <img src={OuterLayer} className="img-layer" alt="" />
                  </div>
                  <h3>
                    Batal Dipecat, Guru Honorer yang Kritik Ridwan Kamil Tidak
                    Mau Kembali Ngajar: Merasa Bersalah
                  </h3>
                  <p>16/03/2023, 11:38 WIB</p>
                  <p>Kompas.com</p>
                </div>
              </div>
              <div className="color-border">
                <div className="article-card">
                  <div className="article-image">
                    <img src={ArticleImage} alt="" />
                    <img src={OuterLayer} className="img-layer" alt="" />
                  </div>
                  <h3>
                    Batal Dipecat, Guru Honorer yang Kritik Ridwan Kamil Tidak
                    Mau Kembali Ngajar: Merasa Bersalah
                  </h3>
                  <p>16/03/2023, 11:38 WIB</p>
                  <p>Kompas.com</p>
                </div>
              </div> */}
            </div>
          </Container>

          {/* Importing Footer */}
          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}
export default Article;
