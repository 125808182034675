import React, { Component, Suspense, useState } from "react";
import {
  Alert,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import LeftBG from "../assets/images/Vector52.png";
import LogoDesa from "../assets/images/register/desapedia.png";
import { config } from "../config";
import LoadingOverlay from "../component/Loading";
import axios from "axios";
const Register = () => {
  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({
    nama: "",
    nomorHp: "",
    desa: "",
  });

  const [errors, setErrors] = useState({
    message: "",
  });

  const [visibleError, setVisibleError] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);

  const [loading, setLoading] = useState(false);

  const onDismiss = () => setVisibleError(false);

  const toggle = () => setModal(!modal);

  const toggleError = () => setModalError(!modalError);

  const toggleSuccess = () => setModalSuccess(!modalSuccess);

  //set preloader div
  const PreLoader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Mengupdate state form menggunakan spread operator dan memodifikasi properti yang sesuai
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleKeyPress = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);

    // Hanya mengizinkan karakter angka dan tombol-tombol khusus (misalnya: arrow keys)
    if (!/^[0-9]+$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  const onSubmit = async () => {
    toggle();
    setLoading(true);
    try {
      const dataToSend = [
        {
          ...form,
          createdBy: "User Landing Page",
          updatedBy: "User Landing Page",
        },
      ];

      const headers = {
        "Content-Type": "application/json",
        apikey: config.API_KEY,
      };

      const response = await axios.post(
        `${config.BASE_URL}register/create`,
        dataToSend,
        { headers }
      );

      if (response.status === 200) {
        setLoading(false);
        toggleSuccess();
        console.log("response: ", response.data);
      }
    } catch (error) {
      setModalError(true);
      setLoading(false);
    }
  };

  const handleConfirm = (event) => {
    event.preventDefault();

    if (!/^\d+$/.test(form.nomorHp)) {
      setVisibleError(true);
      setErrors({
        ...errors,
        message: "Nomor HP tidak valid",
      });
      return;
    } else {
      setVisibleError(false);
      toggle();
      setErrors({
        ...errors,
        message: "",
      });
    }
  };

  return (
    <React.Fragment>
      {loading && <LoadingOverlay />}
      <Modal isOpen={modalSuccess} toggle={toggleSuccess} centered>
        <ModalBody>
          <Alert>
            <h4 className="alert-heading">Register Sukses!</h4>
            <p>
              Selamat Anda sudah sukses mengirimkan data untuk melakukan proses
              pendaftaran, Admin akan melakukan pengecekan data
            </p>
            {/* <hr />
          <p className="mb-0">
            Silahkan menunggu, 
          </p> */}
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ background: "#FF5C30" }}
            size="sm"
            onClick={() => setModalSuccess(false)}
          >
            Tutup
          </Button>{" "}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalError} toggle={toggleError} centered>
        <ModalBody>
          Maaf register saat ini tidak dapat dilakukan. Silahkan coba beberapa
          saat lagi
        </ModalBody>
        <ModalFooter style={{ border: "none" }}>
          <Button
            style={{ background: "#FF5C30" }}
            size="sm"
            onClick={() => setModalError(false)}
          >
            Tutup
          </Button>{" "}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalBody>
          Apakah Anda yakin ingin mengirim data berikut?
          {errors.nomorHp && <p style={{ color: "red" }}>{errors.nomorHp}</p>}
        </ModalBody>
        <ModalFooter style={{ border: "none" }}>
          <Button
            style={{ background: "#FF5C30" }}
            size="sm"
            onClick={onSubmit}
          >
            Iya
          </Button>{" "}
          <Button style={{ background: "gray" }} size="sm" onClick={toggle}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>
      <Suspense fallback={PreLoader}>
        <Container id="register">
          <button
            style={{
              position: "absolute",
              zIndex: "100",
              left: 32,
              top: 24,
              background: "transparent",
              border: 0,
            }}
            onClick={() => (window.location.href = "/")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              fill="white"
              className="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />
            </svg>{" "}
          </button>
          <img src={LeftBG} alt="" />
          <div className="separator">
            <img src={LogoDesa} alt="desapedia" />
            <div className="form">
              <h1>Register</h1>
              {errors.message && (
                <Alert color="danger" isOpen={visibleError} toggle={onDismiss}>
                  {errors.message}
                </Alert>
              )}
              <form action="" onSubmit={handleConfirm}>
                <label htmlFor="nama">Nama Lengkap</label>
                <input
                  name="nama"
                  type="text"
                  id="nama"
                  value={form.nama}
                  onChange={handleInputChange}
                  placeholder="Isi Nama Lengkap Anda"
                />
                <label htmlFor="telfon">No Telp</label>
                <input
                  maxLength={14}
                  name="nomorHp"
                  type="text"
                  id="nomorHp"
                  value={form.nomorHp}
                  onKeyPress={handleKeyPress}
                  onChange={handleInputChange}
                  placeholder="Isi Nomor Telfon / HP Anda"
                />
                <label htmlFor="asal">Asal Desa / Instansi</label>
                <input
                  name="desa"
                  id="desa"
                  type="text"
                  value={form.desa}
                  onChange={handleInputChange}
                  placeholder="Isi nama desa / instansi lengkap Anda"
                />
                <input
                  type="submit"
                  value="Register"
                  disabled={!form.nama || !form.desa || !form.nomorHp}
                />
                {/* <div className="cb">
                    <input type="checkbox" id="check" />
                    <label for="check" className="check">
                      Saya sudah mempelajari panduan ini
                    </label>
                  </div> */}
              </form>
            </div>
          </div>
        </Container>
      </Suspense>
    </React.Fragment>
  );
};
export default Register;
