import React, { useEffect, useState, Suspense } from 'react';
import { Container } from 'reactstrap';
import daftarWarga from '../../assets/images/fitur/Daftar Warga.png';
import daftarPenduduk from '../../assets/images/fitur/Data Penduduk.png';
import daftarPetugas from '../../assets/images/fitur/data_petugas.png';
import pengajuanSurat from '../../assets/images/fitur/pengajuan_surat.png';
import jenisAset from '../../assets/images/fitur/Daftar Jenis Aset.png';
import distribusiBantuan from '../../assets/images/fitur/Daftar Jenis Bantuan.png';
import webProfile from '../../assets/images/fitur/web_desa.png';
import mobileApps from '../../assets/images/fitur/mobile_apps.png';
import mesinAnjungan from '../../assets/images/fitur/mesin_anjungan.png';

const Fitur = () => {
  // Importing Section
  const Navbar = React.lazy(() => import('../../component/Navbar/NavBar'));
  const Footer = React.lazy(() => import('../../component/Footer/Footer'));

  const [navItems, setNavItems] = useState([
    { id: 1, idnm: '/', navheading: 'Beranda', active: false, box: false },
    {
      id: 2,
      idnm: 'fitur',
      navheading: 'Fitur',
      active: true,
      box: false,
    },
    // {
    //   id: 3,
    //   idnm: "artikel",
    //   navheading: "Artikel",
    //   active: false,
    //   box: false,
    // },
    {
      id: 4,
      idnm: 'kontak',
      navheading: 'Kontak',
      active: false,
      box: false,
    },
    {
      id: 5,
      idnm: 'register',
      navheading: 'Register',
      active: false,
      box: true,
    },
    // { id: 1, idnm: "home", navheading: "Home" },
    // { id: 2, idnm: "about", navheading: "About Us" },
    // { id: 3, idnm: "services", navheading: "Services" },
    // { id: 4, idnm: "features", navheading: "Features" },
    // { id: 5, idnm: "project", navheading: "Project" },
    // { id: 6, idnm: "clients", navheading: "Clients" },
    // { id: 7, idnm: "contact", navheading: "Contact Us" },
  ]);

  const [pos, setPos] = useState(document.documentElement.scrollTop);
  const [imglight, setImglight] = useState(false);
  const [navClass, setNavClass] = useState('');

  const scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > pos) {
      setNavClass('nav-sticky');
      setImglight(false);
    }
  };

  const daftarFitur = [
    {
      id: 1,
      title: 'Web Desa',
      subtitle: 'Dapatkan manfaat dari website profil desa',
      deskripsi:
        'Kami membantu Anda untuk memiliki dan memaksimalkan halaman website profil desa yang memuat berbagai informasi seputar desa yang memberikan nilai komersial,  konten menarik dan penting untuk dibaca, mulai dari profil pemerintahan, publikasi kegiatan, data statistik, sampai dengan potensi komoditas dan komunitas, serta  wisata yang dimiliki oleh desa',
      gambar: webProfile,
    },
    {
      id: 2,
      title: 'Mobile App Desa',
      subtitle: 'Desapedia Mobile Aplikasi',
      deskripsi:
        'Kami membantu Warga dalam mendapatkan pelayanan desa terbaik, mudah, cepat, dan aman di genggaman',
      gambar: mobileApps,
    },
    {
      id: 3,
      title: 'Data Penduduk',
      subtitle: 'Pendataan Penduduk AMAN dan Ter-Update',
      deskripsi:
        'Kami memudahkan Anda dalam pendataan, pencarian penduduk dan menyajikannya statistik penduduk dengan tampilan grafik secara akurat dan terupdate.',
      gambar: daftarPenduduk,
    },
    {
      id: 4,
      title: 'Data Warga',
      subtitle: 'Jelas dan Akurat',
      deskripsi:
        'Kami memudahkan data warga secara jelas dan akurat dengan Profil warga, kamanan yang aman, dan memberikan akases yang mudah dalam pencarian warga.',
      gambar: daftarWarga,
    },
    {
      id: 5,
      title: 'Data Petugas',
      subtitle: 'Kenali Petugas desa',
      deskripsi:
        'Kami membantu Warga dalam mengenali petugas desa dengan Profil Petugas dan struktural petugas.',
      gambar: daftarPetugas,
    },
    // {
    //   id: 4,
    //   title: 'Daftar Kepala Desa',
    //   deskripsi:
    //     'Korem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit',
    //   gambar: 'Daftar Kepala Desa.png',
    // },
    // {
    //   id: 5,
    //   title: 'Riwayat Approval Produk',
    //   deskripsi:
    //     'Korem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit',
    //   gambar: 'Riwayat Approval Produk.png',
    // },
    // {
    //   id: 6,
    //   title: 'Banner General',
    //   deskripsi:
    //     'Korem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit',
    //   gambar: 'Banner General.png',
    // },
    // {
    //   id: 7,
    //   title: 'Daftar Organisasi',
    //   deskripsi:
    //     'Korem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit',
    //   gambar: 'Daftar Organisasi.png',
    // },
    {
      id: 6,
      title: 'Administrasi Pengajuan Surat',
      subtitle: 'Tidak Susah untuk Pengajuan Surat dengan DESAPEDIA',
      deskripsi:
        'Desapedia Memberikan Kemudahan bagi warga desa dalam mendapatkan pelayanan pengajuan surat menyurat hanya dengan MOBILE APP DESAPEDIA',
      gambar: pengajuanSurat,
    },
    {
      id: 7,
      title: 'Pendataan Aset Desa',
      subtitle: 'Jangan Bingung dengan pendataan Aset Desa',
      deskripsi:
        'Kami memberikan kemudahan dalam pendataan aset desa sehingga Mudah dalam pendataan, mudah dalam pencarian dan tersusun Rapih',
      gambar: jenisAset,
    },
    {
      id: 8,
      title: 'Mesin Anjungan Desapedia',
      subtitle: 'Mengurus Surat Sangat Mudah dengan Andi Smart',
      deskripsi:
        'Halo Sahabat Desa, Anjungan Digital Mandiri adalah produk inovasi dari DESAPEDIA untuk membantu warga dalam melakukan kegiatan persuratan di Desa. Andi Smart memiliki perangkat lunak yang sudah terintegrasi dengan aplikasi DESAPEDIA sehingga warga desa yang memiliki aplikasi tersebut dapat melakukan request persuratan dan mencetak surat langsung di kantor desa melalui Mesin Anjungan Desapedia.',
      gambar: mesinAnjungan,
    },
    {
      id: 9,
      title: 'Pendistribusian bantuan',
      subtitle: 'Tinggal Tap Bar-Code bantuan cair',
      deskripsi:
        'Kami memberikan kemudahan dalam pendataan dan pendistribusian bantuan, hanya dengan Bar-code saja semua masalah beres',
      gambar: distribusiBantuan,
    },
  ];

  //set preloader div
  const PreLoader = () => {
    return (
      <div id='preloader'>
        <div id='status'>
          <div className='spinner'>
            <div className='bounce1'></div>
            <div className='bounce2'></div>
            <div className='bounce3'></div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollNavigation, true);
  }, []);

  return (
    <React.Fragment>
      <Suspense fallback={PreLoader()}>
        {/* Importing Navbar */}
        <Navbar navItems={navItems} navClass={navClass} imglight={imglight} />

        <>
          <div style={{ marginBottom: 100 }}>
            {daftarFitur.map((data, i) => {
              if (i % 2 === 0) {
                return (
                  <div
                    className='row align-items-center px-5'
                    style={{
                      minHeight: '500px',
                    }}
                  >
                    <div className='col-md-5 p-5'>
                      <h2>{data.title}</h2>
                      <div style={{ fontSize: '1.2rem', fontWeight: 500 }}>
                        {data.subtitle}
                      </div>
                      <div>{data.deskripsi}</div>
                    </div>
                    <div className='col-md-7 p-5'>
                      <img
                        src={data.gambar}
                        alt='Gambar data penduduk Desapedia'
                        className='img-fluid'
                      ></img>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    className='row align-items-center px-5'
                    style={{
                      minHeight: '500px',
                      backgroundColor: '#FFF1F1',
                      width: '100%',
                      marginInline: 0,
                    }}
                  >
                    <div
                      className='col-md-6 p-5'
                      style={{ textAlign: 'center' }}
                    >
                      <img
                        style={{ maxHeight: 450 }}
                        src={data.gambar}
                        alt='Gambar data penduduk Desapedia'
                        className='img-fluid'
                      ></img>
                    </div>
                    <div className='col-md-6 p-5'>
                      <h2>{data.title}</h2>
                      <div style={{ fontSize: '1.2rem', fontWeight: 500 }}>
                        {data.subtitle}
                      </div>
                      <div>{data.deskripsi}</div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </>
        <Footer />
      </Suspense>
    </React.Fragment>
  );
};

export default Fitur;
