import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingOverlay from '../../component/Loading';
import { useHistory } from 'react-router-dom';
import { config } from '../../config';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

const DeleteAccountConfirm = () => {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const params = useParams();
  const [modalError, setModalError] = useState(false);
  const [messageError, setMessageError] = useState('');

  useEffect(() => {
    console.log('masuk');
    const fetchData = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      try {
        console.log('params: ', params);
        const response = await axios.get(
          `${config.BASE_URL_DRIVER}/auth/nonactiveAccount/system/${params.username}?token=${token}`
        );
        if (response.status === 200) {
          history.replace('/delete-account-success');
        }
      } catch (error) {
        // console.log('error: ' + error.response.data.message);
        // history.push('/');
        if (error.response.data.data === 'EXPIRED') {
          setMessageError(error.response.data.message);
          setModalError(true);
        } else {
          history.push('/');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const toggleError = () => setModalError(!modalError);

  return (
    <>
      <Modal isOpen={modalError} centered>
        <ModalBody>{messageError}</ModalBody>
        <ModalFooter style={{ border: 'none' }}>
          <Button
            style={{ background: '#FF5C30' }}
            size='sm'
            onClick={() => history.push('/delete-account')}
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>
      {isLoading && <LoadingOverlay />}
    </>
  );
};

export default DeleteAccountConfirm;
